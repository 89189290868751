<template>
    <div class="transitionPage">
        <div id="Residency">
            <h1>Residency</h1>
            <h2 class="small">Residency Directors: Cassandra Hradil, Andrew W. Smith</h2>
			<p>The salt.codes <b>Digital Experimentation Residency</b> is a 60 day virtual residency for a digital artist, designer, or writer to create an iterative work of art or literature using web technologies. This may take the form of episodic electronic literature, an expanding game world, a growing/changing visual piece or video installation, or any other digital work that can live on a website.</p>
            <p>We are particularly interested in proposals that take a creative approach to the iterative nature of the Residency, finding ways to build upon the project publicly with new iterations, additional components, or altered experiences being released over the 60 day period.</p>
            <h2>Current Resident</h2>
            <p>There is no current Resident. Maybe it could be you? We'll be opening applications soon<sup>TM</sup>!</p>
            <h2>Past Residents</h2>
            <p>We just started this thing. Who's going to be first?</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Residency"
    }
</script>

<style scoped lang="scss">
    #Residency {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
    }

</style>