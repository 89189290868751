<template>
    <div class="transitionPage">
        <div id="Curation">
            <h1>Curations</h1>
            <p>salt.codes is host to a series of virtual exhibits, anthologies, and collections of digital art and literature. Each collection is curated and edited by members of the Immersive Realities Labs for the Humanities. Completed curations available for experiencing may be found here.</p>
            <div class="call" v-for="call in calls" :key="call.id">
                <div class="box">
                    <div class="img">
                        <img :src="call.banner" alt="" class="banner">
                    </div>
                    <div class="info">
                        <h2 class="title">{{ call.title }}</h2>
                        <div class="detailsBox">
                            <h3>Curators :: {{ call.people }}</h3>
                            <h3>When :: <span class="bold">{{ call.when }}</span></h3>
                            <h3>Availability :: <a :href="call.availability" class="bold">{{ call.availability }}</a></h3>
                        </div>
                        <p>{{ call.about  }} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
    import firebaseApp from "@/firebase";

    const db = getFirestore(firebaseApp);
    export default {
        name: "Curation",
        data() {
            return {
                calls: []
            }
        },
        async created() {
            const q = query(collection(db, "calls"), where("display", "==", true), where("category", "==", "Curation"));
            const querySnapshot = await getDocs(q)

            querySnapshot.forEach((doc) => {
                let call = doc.data();
                call.id = doc.id;
                this.calls.push(call);
            })
        }
    }
</script>

<style scoped lang="scss">
    #Curation {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
    }
    .title {
        margin-top: 0;
    }
    .img {
        img {
            width: 200px;
        }
    }
    .call {
        color: #312C4D;
        padding-bottom: 18px;
    }

    .box {
        display: flex;
        flex-direction: row;
        grid-gap: 40px;
        @media only screen and (max-width: 800px) {
            grid-gap: 20px;
            flex-direction: column;
        }
    }

    .detailsBox {
        width: 100%;
    }

    h2 {
        margin-bottom: 8px;
    }

    h3 {
        font-family: 'roboto-slab', serif;
        font-size: 20px;
        margin: 4px 0;
        word-break: break-word;
    }

    p {
        margin-top: 8px;
    }
</style>