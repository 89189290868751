<template>
    <div className="transitionPage">
        <div id="Transmutations">
            <h1>Transmutation Lab</h1>
            <p>Our Transmutation Lab develops methodologies for materializing born-digital literature and scholarship as physical texts. This experimental practice treads the boundaries between the virtual and physical, the digital and analog, seeking to expand the ways we imagine they intersect and to better understand how experiences can and cannot be translated between them.</p>
            <p>We'll be sharing more about the work being done by this sub-lab in the future...</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Transmutations"
    }
</script>

<style scoped lang="scss">
    #Transmutations {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
    }

</style>