<template>
    <div class="transitionPage">
        <div id="NotFound">
            <h1>Not Found</h1>
            <p>We don't have this page! Our URLs might have changed or the wrong link was shared. Please explore the site to find what you are looking for.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>
    #NotFound {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
    }
</style>