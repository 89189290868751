<template>
    <div class="transitionPage">
        <div id="CurrentCalls">
            <h1>Current Calls</h1>
            <p v-if="calls.length === 0">There are no current open calls. Check back later!</p>
            <div class="call" v-for="call in calls" :key="call.id">
                <h2>{{ call.category }} :: {{ call.title }}</h2>
                <div class="box">
                    <div class="detailsBox">
                        <h3>{{ call.position }} :: {{ call.people }}</h3>
                        <h3>Submission Guidelines / Form :: <a :href="call.link" class="bold">{{ call.link }}</a></h3>
                        <h3>Deadline :: <span class="bold">{{ call.deadline }}</span></h3>
                    </div>
                    <p>{{ call.description  }} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
    import firebaseApp from "@/firebase";

    const db = getFirestore(firebaseApp);

    export default {
        name: 'CurrentCalls',
        data() {
            return {
                calls: []
            }
        },
        async created() {
            const q = query(collection(db, "calls"), where("activeCall", "==", true));
            const querySnapshot = await getDocs(q)

            querySnapshot.forEach((doc) => {
                let call = doc.data();
                call.id = doc.id;
                this.calls.push(call);
            })
            this.calls = this.calls.sort((a, b) => {
                let fa = a.order.toLowerCase(),
                    fb = b.order.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        }
    }
</script>

<style scoped lang="scss">
    #CurrentCalls {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
    }

    .call {
        color: #312C4D;
        padding-bottom: 18px;
    }

    .box {
        padding: 0 12px 0 12px;
        border-left: 5px solid #665C9F;
        margin-left: 8px;
    }

    .detailsBox {

    }

    h2 {
        margin-bottom: 8px;
    }

    h3 {
        font-family: 'roboto-slab', serif;
        font-size: 20px;
        margin: 4px 0;
        a {
            word-break: break-word;
        }
    }

    p {
        margin-top: 8px;

    }
</style>