import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCbAUEDRWlmsV_FiO9Z82Q4twtdlabzliM",
    authDomain: "salt-codes.firebaseapp.com",
    projectId: "salt-codes",
    storageBucket: "salt-codes.appspot.com",
    messagingSenderId: "538211939915",
    appId: "1:538211939915:web:250b0fefa77502d768bfce",
    measurementId: "G-EC6JR13L77"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp