<template>
    <div id="Footer">
        <div class="footerContent">
            <h2 class="footerTitle">Salted Earth :: Woven Codes</h2>
            <div class="container">
                <div class="irlh">
                    <a href="https://irlhumanities.org" target="_blank">
                        <img src="https://awikhigan.irlh.org/gen/wl/?id=NEl7v2nuzOwDqjWFPJlX6nufMw312RRa"
                             alt="immersive realities labs for the humanities" class="irlhBanner">
                    </a>
                </div>
                <div class="footerMenu">
                    <a href="https://mailchi.mp/14b7ba71b5e5/saltcodes" target="_blank">
                        <p>Newsletter</p>
                    </a>
                    <div class="socialmedia">
                        <a href="https://twitter.com/salt_codes" target="_blank">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=euaEy0WeKJ0OBk6BbMEhF7XtEzC63ham"
                                 alt="twitter icon"
                                 class="socialmedia-icon">
                        </a>
                        <a href="https://www.instagram.com/salt.codes/" target="_blank">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=KZM3sXQck2IQboR37Hu5pZDm19sPuVeC"
                                 alt="instagram icon"
                                 class="socialmedia-icon">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped lang="scss">
    a {
        color: #FFE49E;
        text-decoration: none;

        &:hover {
            color: #BDA5C7;
        }
    }

    #Footer {
        background-color: #312C4D;
        color: #FFE49E;
        max-height: 250px;
        width: 100%;
        margin-top: 40px;
        @media only screen and (max-width: 800px) {
            height: 100%;
        }
    }

    .footerContent {
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        padding: 32px 20px 32px 20px;
        @media only screen and (max-width: 800px) {
            padding: 32px 20px 0 20px;
        }
    }

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        align-items: center;
        @media only screen and (max-width: 800px) {
            width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            grid-gap: 10px;
        }
    }

    .footerTitle {
        color: #BDA5C7;
        font-family: 'comodo', sans-serif;
        font-size: 26px;
        font-weight: normal;
        letter-spacing: 1px;
        text-align: center;
        margin: 0;
    }

    .irlhBanner {
        width: 400px;
        @media only screen and (max-width: 800px) {
            width: 100%;
        }
    }

    .footerMenu {
        text-align: right;

        p {
            font-size: 18px;
            margin: 4px 0;
        }
    }

    .socialmedia {
        display: flex;
        flex-direction: row;
        justify-content: end;
        grid-gap: 20px;
        padding: 10px;
        @media only screen and (max-width: 800px) {
            padding: 10px 10px 0 10px;
        }
        .socialmedia-icon {
            width: 25px;
        }
    }
</style>