import {createRouter, createWebHistory} from 'vue-router'

import CurrentCalls from "@/pages/CurrentCalls";
import Bytes from "@/pages/Bytes";
import Residency from "@/pages/Residency";
import Curation from "@/pages/Curation";
import Transmutations from "@/pages/Transmutations";
import Home from "@/pages/Home";
import NotFound from "@/pages/NotFound";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/currentcalls',
        name: 'CurrentCalls',
        component: CurrentCalls
    },
    {
        path: '/bytes',
        name: 'Bytes',
        component: Bytes
    },
    {
        path: '/residency',
        name: 'Residency',
        component: Residency
    },
    {
        path: '/curations',
        name: 'Curation',
        component: Curation
    },
    {
        path: '/transmutationlab',
        name: 'Transmutations',
        component: Transmutations
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/constellations-an-interview-series-a6dfdfa96b96',
        beforeEnter() {location.href = 'https://bytes.salt.codes/constellations-an-interview-series-a6dfdfa96b96'}
    },
    {
        path: '/anti-racist-and-decolonial-dh-reading-list-49fff155afd6',
        beforeEnter() {location.href = 'https://bytes.salt.codes/anti-racist-and-decolonial-dh-reading-list-49fff155afd6'}
    },
    {
        path: '/this-morning-in-blackness-deep-nostalgia-edition-cc117ed844af',
        beforeEnter() {location.href = 'https://bytes.salt.codes/this-morning-in-blackness-deep-nostalgia-edition-cc117ed844af'}
    },
    {
        path: '/collapse-a-practice-of-urgent-remixing-853c24fd3f76',
        beforeEnter() {location.href = 'https://bytes.salt.codes/collapse-a-practice-of-urgent-remixing-853c24fd3f76'}
    },
    {
        path: '/luxury-and-liberation-e46ff648a38f',
        beforeEnter() {location.href = 'https://bytes.salt.codes/luxury-and-liberation-e46ff648a38f'}
    },
    {
        path: '/black-dh-black-data-1c897acb7647',
        beforeEnter() {location.href = 'https://bytes.salt.codes/black-dh-black-data-1c897acb7647'}
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
