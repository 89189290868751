<template>
    <div class="totalPage">
        <Nav/>
        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component"/>
            </transition>
        </router-view>
        <Footer/>
    </div>
</template>

<script>
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";

    export default {
        components: {Footer, Nav},
    }
</script>

<style lang="scss">
    @import url("https://use.typekit.net/xds3rex.css");

    @font-face {
        font-family: comodo, sans-serif;
        font-weight: 400;
        font-style: normal;
        src: url('assets/comodo.otf') format('otf');
        src: url('assets/Comodo-Regular.ttf') format('ttf');
        src: url('assets/Comodo-Regular.woff') format('woff');
    }

    @font-face {
        font-family: roboto-slab, serif;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: roboto-slab, serif;
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: roboto-mono, monospace;
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: roboto-mono, monospace;
        font-weight: 400;
        font-style: normal;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
    }

    html {
        scroll-behavior: smooth;
    }

    .totalPage {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }

    .transitionPage {
        height: 100%;
        width: 100%;
        flex-grow: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transition: opacity .25s ease;
    }

    h1 {
        font-family: 'comodo', sans-serif;
        font-weight: 400;
        font-size: 42px;
        color: #312C4D;
    }

    h2 {
        font-family: 'roboto-slab', serif;
        font-weight: 700;
        font-size: 30px;
    }

    h3 {
        font-family: 'roboto-mono', monospace;
        font-weight: 400;
        font-size: 24px;
    }

    p {
        font-family: 'roboto-mono', monospace;
        font-weight: 300;
        font-size: 20px;
    }

    body {
        margin: 0;
    }

    .bold {
        color: #665C9F;
        font-weight: 700;
    }

    .small {
        font-size: 20px;
        margin: 4px 0;
    }

    .paraLink {
        color: #665C9F;
        font-weight: 700;
        &:hover {
            color: #312C4D;
        }
    }
    .irlhLink {
        color: #312C4D;
    }
    .lifexcode {
        color: #312C4D;
        font-weight: normal;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
</style>
