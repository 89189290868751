<template>
    <img src="https://awikhigan.irlh.org/gen/wl/?id=kETM5groCsDcN3EerQI0MAmnAlzLTYcr" alt="" class="banner">
    <div class="navBar">
        <div class="navLinks">
            <router-link :to="{ name: 'Home' }">
                <h3>Home</h3>
            </router-link>
            <router-link :to="{ name: 'CurrentCalls' }">
                <h3>Current Calls</h3>
            </router-link>
            <router-link :to="{ name: 'Bytes' }">
                <h3>Bytes</h3>
            </router-link>
            <router-link :to="{ name: 'Residency' }">
                <h3>Residency</h3>
            </router-link>
            <router-link :to="{ name: 'Curation' }">
                <h3>Curations</h3>
            </router-link>
            <router-link :to="{ name: 'Transmutations' }">
                <h3>Transmutation Lab</h3>
            </router-link>
        </div>
        <div class="navContainerMobile">
            <div class="mobileMenuIcon" @click="mobileToggle">
                <div id="hamburgerIcon" class="container" onclick="this.classList.toggle('active')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 200 200">
                        <g stroke-width="6.5" stroke-linecap="round">
                            <path
                                d="M72 82.286h28.75"
                                fill="#009100"
                                fill-rule="evenodd"
                                stroke="#fff"
                            />
                            <path
                                d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                                fill="none"
                                stroke="#fff"
                            />
                            <path
                                d="M72 125.143h28.75"
                                fill="#009100"
                                fill-rule="evenodd"
                                stroke="#fff"
                            />
                            <path
                                d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                                fill="none"
                                stroke="#fff"
                            />
                            <path
                                d="M100.75 82.286h28.75"
                                fill="#009100"
                                fill-rule="evenodd"
                                stroke="#fff"
                            />
                            <path
                                d="M100.75 125.143h28.75"
                                fill="#009100"
                                fill-rule="evenodd"
                                stroke="#fff"
                            />
                        </g>
                    </svg>
                </div>
            </div>
            <div id="mobileDisplay" class="navLinksMobile">
                <router-link :to="{ name: 'Home' }" @click="closeMenu">
                    <h3>Home</h3>
                </router-link>
                <router-link :to="{ name: 'CurrentCalls' }" @click="closeMenu">
                    <h3>Current Calls</h3>
                </router-link>
                <router-link :to="{ name: 'Bytes' }" @click="closeMenu">
                    <h3>Bytes</h3>
                </router-link>
                <router-link :to="{ name: 'Residency' }" @click="closeMenu">
                    <h3>Residency</h3>
                </router-link>
                <router-link :to="{ name: 'Curation' }" @click="closeMenu">
                    <h3>Curations</h3>
                </router-link>
                <router-link :to="{ name: 'Transmutations' }" @click="closeMenu">
                    <h3>Transmutation Lab</h3>
                </router-link>
            </div>
        </div>
    </div>
    <div class="floater">
        <h2>salt.codes</h2>
        <h3>an experimental publishing project<br/>by the immersive realities labs</h3>
    </div>
</template>

<script>
    export default {
        name: "Nav",
        methods: {
            mobileToggle() {
                const x = document.getElementById('mobileDisplay');
                if (x.style.display === "flex") {
                    x.style.display = "none";
                } else {
                    x.style.display = "flex";
                }
            },
            closeMenu() {
                const x = document.getElementById('hamburgerIcon');
                x.classList.toggle('active')

                this.mobileToggle()
            },
        }
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: none;
        color: #FFE49E;

        &:hover {
            color: #ffd0f8;
        }
    }

    .banner {
        width: 100%;
        height: 400px;
        object-fit: cover;
        object-position: top;
    }

    .navBar {
        background-color: #665C9F;
    }

    .navLinks {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        grid-gap: 60px;
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
        color: #FFE49E;
        padding: 16px 0;
        @media only screen and (max-width: 800px) {
            display: none;
        }
        h3 {
            font-family: 'comodo', sans-serif;
            letter-spacing: 1px;
            font-weight: normal;
            cursor: pointer;
            margin: 0;
            font-size: 26px;
            text-align: center;
        }
    }

    .floater {
        position: absolute;
        top: 40px;
        left: 40px;
        background-color: rgba(49, 44, 77, 0.85);
        color: #FFE49E;
        padding: 8px;
        @media only screen and (max-width: 800px) {
            top: 60px;
            left: 20px;
            width: 80%;
        }
        h2 {
            font-family: 'comodo', sans-serif;
            font-weight: normal;
            margin: 0;
        }

        h3 {
            font-family: 'Roboto Mono', monospace;
            font-weight: normal;
            margin: 0;
        }
    }

    .navContainerMobile {
        background-color: #665C9F;
        display: none;

        .mobileMenuIcon {
            color: white;
            font-size: 24px;
            text-align: center;
        }

        .navLinksMobile {
            display: none;
            flex-direction: column;
            grid-gap: 20px;
            width: 100%;
            margin: 0 auto;
            color: white;
            padding-bottom: 20px;
        }

        h3 {
            font-weight: bold;
            cursor: pointer;
            margin: 0;
            font-size: 24px;
            padding: 0 0 0 40px;
        }

        @media only screen and (max-width: 800px) {
            display: block;
        }
    }
    svg {
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .active svg {
        transform: rotate(90deg);
    }

    path {
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    path:nth-child(1) {
        transform-origin: 36% 40%;
    }

    path:nth-child(2) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(3) {
        transform-origin: 35% 63%;
    }

    path:nth-child(4) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(5) {
        transform-origin: 61% 52%;
    }

    path:nth-child(6) {
        transform-origin: 62% 52%;
    }

    .active path:nth-child(1) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }

    .active path:nth-child(2) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    .active path:nth-child(3) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    .active path:nth-child(4) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    .active path:nth-child(5) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    .active path:nth-child(6) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }
</style>