<template>
    <div class="transitionPage">
        <div id="Home">
            <h1>salt.codes</h1>
            <p><i>salt.codes</i> is an experimental publishing project operating at the boundaries of digital worlds.
                The
                project is run and maintained by members of the
                <a href="https://irlhumanities.org" target="_blank" class="irlhLink">Immersive Realities Labs for the
                    Humanities
                    (irLh)</a>. We’re here to catalog thought bytes from irLh and our wider community of artists,
                scholars, designers, and technologists.</p>
            <div class="components">
                <h2>Our Grains of Salt</h2>
                <router-link :to="{ name: 'Bytes' }">
                    <h3>Bytes</h3>
                </router-link>
                <div class="comp">
                    <p>Bytes are short and experimental written pieces by members of irLh and our wider community of
                        artists, technologists, and scholars. In collaboration with <a href="https://www.lifexcode.org/"
                                                                                       target="_blank"
                                                                                       class="lifexcode">Life X
                            Codes</a>.</p>
                </div>
                <router-link :to="{ name: 'Residency' }">
                    <h3>Residency</h3>
                </router-link>
                <div class="comp">
                    <p>Our 60 day Residency is an experimental model that creates space for digital artists and writers
                        to
                        develop an iterative work in public.</p>
                </div>
                <router-link :to="{ name: 'Curation' }">
                    <h3>Curations</h3>
                </router-link>
                <div class="comp">
                    <p>Curations are digital exhibits, anthologies, and collections of digital art and electronic
                        literature
                        curated by members of irLh and invited guests.</p>
                </div>
                <router-link :to="{ name: 'Transmutations' }">
                    <h3 class="hoverGlitch">Transmutation Lab</h3>
                </router-link>
                <div class="comp">
                    <p>A experimental research group for translating long-form works of born-digital literature into
                        physical form.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Home"
    }
</script>

<style scoped lang="scss">
    #Home {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
    }

    .irlhLink {
        text-decoration: none;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
        }
    }

    .components {
        .comp {
            background-color: rgba(189, 165, 199, .25);
            padding: 6px 12px;
            margin-bottom: 24px;
        }

        h3 {
            display: inline-block;
            background-color: #665C9F;
            color: #FFE49E;
            padding: 4px 8px;
            margin: 6px 0 0 0;
            font-size: 22px;
        }

        p {
            margin: 6px 0;
        }
    }

</style>