<template>
    <div class="transitionPage">
        <div id="Bytes">
            <h1>salt.codes Bytes</h1>
            <p>Bytes is an experimental digital publication for short-form digital essays, as well as interviews,
                process pieces,
                reading lists, and more. We publish thoughtful writing engaging with digital media, art and performance,
                ecological + technological futurism, design and creative practice, and their intersections with our
                lives and identities. Bytes is run in collaboration with <a href="https://www.lifexcode.org/"
                                                                            target="_blank" class="lifexcode">Life x
                    Code: DH Against Enclosure</a>. We are currently hosted over on Medium.</p>
            <!--            <p>Thinking about submitting? We want to hear from you!</p>-->
            <!--            <div class="submissionButton">-->
            <!--                <router-link :to="{ name: 'JournalSubmissionGuidelines' }" class="paraLink">-->
            <!--                    <h2>Check out our Submission Guidelines</h2>-->
            <!--                </router-link>-->
            <!--            </div>-->
            <div class="mediumButton">
                <a href="https://bytes.salt.codes" target="_blank">
                    <h2 class="btnText">View us on Medium</h2>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bytes",
    }
</script>

<style scoped lang="scss">
    #Bytes {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
    }

    .submissionButton {
        h2 {
            font-size: 24px;
            display: inline-block;
            background-color: #665C9F;
            color: #FFF;
            padding: 12px 20px;
            font-weight: normal;
            transition: .25s;

            &:hover {
                color: #FFE49E;
            }
        }
    }

    .mediumButton {
        .btnText {
            font-size: 24px;
            display: inline-block;
            background-color: #665C9F;
            color: #FFF;
            padding: 12px 20px;
            font-weight: normal;
            transition: .25s;

            &:hover {
                color: #FFE49E;
            }
        }
    }

    .featuredArticles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;
    }

    .article {
        display: flex;
        flex-direction: column;
        text-decoration: none;

        &:hover .articleImage {
            border: 4px solid #665C9F;
        }
    }

    .articleImage {
        height: 225px;
        width: 350px;
        object-fit: cover;
        object-position: center;
        border: 4px solid #BDA5C7;
        margin-bottom: 2px;
    }

    .articleTitle {
        width: 350px;
        margin: 0;
        font-size: 22px;
    }

    .author {
        width: 350px;
        font-size: 18px;
        margin: 0;
        color: #665C9F;
    }

    .caption {
        width: 350px;
        font-size: 16px;
        margin: 0;
    }

    .date {
        width: 350px;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        color: #312C4D;
    }

</style>